/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';
import { Plus } from 'react-feather';
import LayoutContext from '../../context/layoutcontext';
import SettingsForm from './settingsform';
import SettingsActions from './settingsactions';
// import DeleteDialog from '../common/deletedialog';
import API from '../../api';
import { errorMessageHandler } from '../../utils/errormessagehandler';
import AuthContext from '../../context/authcontext';
import { useSnackbar } from 'notistack';
// import Loader from '../common/loader';
import debounce from 'lodash.debounce';
import NoDataDiv from '../common/nodatadiv';
import SearchIcon from '@mui/icons-material/Search';
import NewDeleteDialog from '../common/newdeletedialog';
import LinerLoader from '../common/linearLoader';
// import SchedulerDialog from '../families/scheduler';
import DefaultScheduler from '../families/defaultScheduler';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';

const Settings = () => {
  const layoutCtx = useContext(LayoutContext);
  const authCtx = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isUserFormDialogOpen, setIsUserFormDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [customerDetails, setCustomerDetails] = useState(null);
  const [totalLocations, setTotalLocations] = useState(0);
  const [location, setLocation] = useState();
  const [activeLocations, setActiveLocations] = useState(0);
  const [usersPayload, setUsersPayload] = useState({
    pageNumber: 0,
    pageSize: parseInt(process.env.REACT_APP_PAGINATION_LIMIT, 10),
    searchBy: '',
    location: 'All',
    cust_id: localStorage.getItem('cust_id')
  });
  const [value, setValue] = useState('1');
  const [timer, setTimer] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [stripeCust, setStripeCust] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productQuantities, setProductQuantities] = useState({});
  const [checked, setChecked] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [trialDays, setTrialDays] = useState(0);
  const [scheduledPrices, setScheduledPrices] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  const stripe_cust_id = authCtx.user.stripe_cust_id;

  // Fetch products from the backend
  useEffect(() => {
    fetchProducts();
    fetchScheduledSubscriptions();
    fetchSubscriptions();
    getCustPaymentMethod();
  }, []);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await API.get('payment/list-products');
      if (response.status === 200) {
        const data = await response.data;
        const priceList = response.data.data.priceList.data;
        const productList = response.data.data.products.data;
        const updatedProductList = productList.map((product) => {
          // Find the corresponding price in the priceList
          const price = priceList.find((price) => price.id === product.default_price);
          // If a matching price is found, attach its unit_amount to the product
          if (price) {
            return {
              ...product,
              price_id: price.id,
              unit_amount: price.unit_amount
            };
          } else {
            return product;
          }
        });
        setProducts(updatedProductList);
      } else {
        errorMessageHandler(
          enqueueSnackbar,
          response?.response?.data?.Message || 'Something Went Wrong.',
          response?.response?.status,
          authCtx.setAuthError
        );
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchScheduledSubscriptions = async () => {
    try {
      setIsLoading(true);
      const response = await API.get('payment/list-scheduled-subscriptions', {
        params: {
          stripe_cust_id: authCtx.user.stripe_cust_id,
          cust_id: localStorage.getItem('cust_id')
        }
      });
      if (response.status === 200) {
        const data = await response.data.data.localSubscriptions;
        // setScheduledPrices(data.map((item) => item.phases[0].items[0].price));
        setScheduledPrices(data.map((item) => item.plan));
      } else {
        errorMessageHandler(
          enqueueSnackbar,
          response?.response?.data?.Message || 'Something Went Wrong.',
          response?.response?.status,
          authCtx.setAuthError
        );
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchSubscriptions = async () => {
    try {
      setIsLoading(true);
      const response = await API.get('payment/list-subscriptions', {
        params: {
          stripe_cust_id: authCtx.user.stripe_cust_id,
          cust_id: localStorage.getItem('cust_id')
        }
      });
      const data = await response.data.data.subscriptions;
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckChange = (event, productId, price_id = 123, qty) => {
    if (event.target.checked) {
      setSelectedProducts((prevSelected) => [...prevSelected, productId]);
      setChecked([
        ...checked,
        {
          product: {
            [event.target.name]: event.target.value,
            qty: qty,
            price_id: price_id
          }
        }
      ]);
    } else {
      setSelectedProducts((prevSelected) => prevSelected.filter((id) => id !== productId));

      setChecked((prevChecked) => {
        const arr = prevChecked.filter(({ product }) => product.price_id !== price_id);
        return arr;
      });
    }
  };

  useEffect(() => {
    layoutCtx.setActive(null);
    layoutCtx.setBreadcrumb(['Settings', 'Manage Settings']);
    return () => {
      authCtx.setPreviosPagePath(window.location.pathname);
    };
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    getLocationsList();
  }, [usersPayload]);

  useEffect(() => {
    getDefaultScheduleSettings();
  }, []);

  // Method to fetch location list for table
  const getLocationsList = () => {
    setIsLoading(true);
    API.get('customers/all/locations', { params: usersPayload }).then((response) => {
      if (response.status === 200) {
        setLocationsList(response.data.Data.locations);
        setTotalLocations(response.data.Data.count);
        setCustomerDetails(response.data.Data.customer);
        setActiveLocations(response.data.Data.activeLocations);
      } else {
        errorMessageHandler(
          enqueueSnackbar,
          response?.response?.data?.Message || 'Something Went Wrong.',
          response?.response?.status,
          authCtx.setAuthError
        );
      }
      setIsLoading(false);
    });
  };

  // Method to fetch Default Settings for Schedule
  const getDefaultScheduleSettings = () => {
    setIsLoading(true);
    API.get('family/child/schedule', {
      params: {
        cust_id: authCtx.user.cust_id || localStorage.getItem('cust_id')
      }
    }).then((response) => {
      if (response.status === 200) {
        setTimer(response.data.Data.schedule.timeRange);
        setSelectedDays(response.data.Data.schedule.timeRange[0][1]);
        // setLocationsList(response.data.Data.locations);
      } else {
        errorMessageHandler(
          enqueueSnackbar,
          response?.response?.data?.Message || 'Something Went Wrong.',
          response?.response?.status,
          authCtx.setAuthError
        );
      }
      setIsLoading(false);
    });
  };

  // Method to fetch Customer Payment Method along with Customer Details
  const getCustPaymentMethod = () => {
    setIsLoading(true);
    API.get('payment/list-customer-payment-method', {
      params: { stripe_cust_id: stripe_cust_id, cust_id: localStorage.getItem('cust_id') }
    }).then((response) => {
      if (response.status === 200) {
        setStripeCust(response.data.customerDetails);
      } else {
        errorMessageHandler(
          enqueueSnackbar,
          response?.response?.data?.message || 'Something Went Wrong.',
          response?.response?.status,
          authCtx.setAuthError
        );
      }
      setIsLoading(false);
    });
  };

  // Method to delete location
  const handleLocationDelete = () => {
    setDeleteLoading(true);
    let payload = {
      loc_id: location.loc_id
    };
    API.delete('customers/deleteCustomerLocation', {
      data: { ...payload }
    }).then((response) => {
      if (response.status === 200) {
        getLocationsList();
        enqueueSnackbar(response.data.Message, {
          variant: 'success'
        });
      } else {
        errorMessageHandler(
          enqueueSnackbar,
          response?.response?.data?.Message || 'Something Went Wrong.',
          response?.response?.status,
          authCtx.setAuthError
        );
      }
      setLocation();
      setDeleteLoading(false);
      setIsDeleteDialogOpen(false);
    });
  };

  // Method to change the page in table
  const handlePageChange = (_, newPage) => {
    setUsersPayload((prevPayload) => ({ ...prevPayload, pageNumber: newPage }));
  };

  // Method to change the row per page in table
  const handleChangeRowsPerPage = (event) => {
    setUsersPayload((prevPayload) => ({
      ...prevPayload,
      pageSize: parseInt(event.target.value, 10)
    }));
  };

  // Method to handle Search for table
  const handleSearch = (event) => {
    setUsersPayload((prevPayload) => ({
      ...prevPayload,
      pageNumber: 0,
      searchBy: event.target.value ? event.target.value : ''
    }));
  };

  // Calls the search handler after 500ms
  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);

  const handleSubmit = (data) => {
    const payload = {
      cust_id: localStorage.getItem('cust_id'),
      userId: authCtx.user.stripe_cust_id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      description: data.description,
      country: data.country,
      state: data.state,
      city: data.city
    };
    setIsLoading(true);
    setSubmitLoading(true);
    API.put('payment/update-customer', payload).then((response) => {
      if (response.status === 200) {
        getCustPaymentMethod();
      } else {
        errorMessageHandler(
          enqueueSnackbar,
          response?.response?.data?.message || 'Something Went Wrong.',
          response?.response?.status,
          authCtx.setAuthError
        );
      }
      setIsLoading(false);
      setSubmitLoading(false);
    });
  };
  const handleIncrement = (productId) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: (prevQuantities[productId] || 0) + 1 // Increment quantity by 1
    }));
  };

  const handleDecrement = (productId) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.max(0, prevQuantities[productId] - 1) // Ensure quantity doesn't go below 0
    }));
  };

  const getProductQuantity = (productName) => {
    switch (productName) {
      case 'Mobile Live Stream Room License':
        return authCtx.user.max_stream_live_license_room;
      case 'Sentry Perimeter Monitoring License':
        return authCtx.user.max_stream_live_license;
      default:
        return 1;
    }
  };

  const handleCheckout = async () => {
    // Given date
    const givenDate = moment(startDate);
    // Calculate the end date by adding days to the given date
    const endDate = givenDate.clone().add(trialDays, 'days');

    // Convert end date to Unix timestamp
    const unixTimestamp = endDate.unix();
    setIsLoading(true);
    API.post('payment/create-checkout', {
      cust_id: localStorage.getItem('cust_id'),
      stripe_cust_id: authCtx.user?.stripe_cust_id,
      products: checked,
      startDate: moment(startDate).unix(),
      trial_period_days: trialDays
    }).then((response) => {
      if (response.status === 200) {
        console.log(response.data);
        enqueueSnackbar('Successfully subscribed!', {
          variant: 'success'
        });
        setTrialDays(0);
        setStartDate(moment());
        fetchProducts();
        fetchScheduledSubscriptions();
        setChecked([]);
      } else {
        errorMessageHandler(
          enqueueSnackbar,
          response?.response?.data?.message || 'Something Went Wrong.',
          response?.response?.status,
          authCtx.setAuthError
        );
      }
      setIsLoading(false);
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {(authCtx.user.role === 'Admin' || authCtx.user.role === 'Super Admin') && (
              <Tab
                sx={{ textTransform: 'none', fontSize: '16px' }}
                label="Customer Profile"
                value="1"
              />
            )}
            <Tab sx={{ textTransform: 'none', fontSize: '16px' }} label="Locations" value="2" />
            <Tab sx={{ textTransform: 'none', fontSize: '16px' }} label="Cameras" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{ position: 'relative' }}>
            <LinerLoader loading={isLoading} />
            <Card>
              <CardHeader title="Stripe Account Details"></CardHeader>
              <CardContent>
                <Formik
                  enableReinitialize
                  validateOnChange
                  // validationSchema={validationSchema}
                  initialValues={{
                    name: stripeCust?.name || '',
                    email: stripeCust?.email || '',
                    phone: stripeCust?.phone || '',
                    description: stripeCust?.description || '',
                    city: stripeCust?.address?.city || '',
                    state: stripeCust?.address?.state || '',
                    country: stripeCust?.address?.country || ''
                  }}
                  onSubmit={handleSubmit}>
                  {({ values, setFieldValue, touched, errors }) => {
                    return (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <TextField
                              label="Name"
                              name="name"
                              value={values?.name}
                              onChange={(event) => {
                                setFieldValue('name', event.target.value);
                              }}
                              helperText={touched.name && errors.name}
                              error={touched.name && Boolean(errors.name)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              label="Email"
                              name="email"
                              value={values?.email}
                              onChange={(event) => {
                                setFieldValue('email', event.target.value);
                              }}
                              helperText={touched.email && errors.email}
                              error={touched.email && Boolean(errors.email)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              label="Phone"
                              name="phone"
                              value={values?.phone}
                              onChange={(event) => {
                                setFieldValue('phone', event.target.value);
                              }}
                              helperText={touched.phone && errors.phone}
                              error={touched.phone && Boolean(errors.phone)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              label="Description"
                              name="description"
                              value={values?.description}
                              onChange={(event) => {
                                setFieldValue('description', event.target.value);
                              }}
                              helperText={touched.description && errors.description}
                              error={touched.description && Boolean(errors.description)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Typography variant="subtitle1">Address</Typography>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              label="Country"
                              name="country"
                              value={values?.country}
                              onChange={(event) => {
                                setFieldValue('country', event.target.value);
                              }}
                              helperText={touched.description && errors.description}
                              error={touched.description && Boolean(errors.description)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              label="State"
                              name="state"
                              value={values?.state}
                              onChange={(event) => {
                                setFieldValue('state', event.target.value);
                              }}
                              helperText={touched.description && errors.description}
                              error={touched.description && Boolean(errors.description)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              label="City"
                              name="city"
                              value={values?.city}
                              onChange={(event) => {
                                setFieldValue('city', event.target.value);
                              }}
                              helperText={touched.description && errors.description}
                              error={touched.description && Boolean(errors.description)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Stack
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              spacing={3}>
                              {authCtx.user.role === 'Super Admin' && (
                                <LoadingButton
                                  loading={submitLoading}
                                  loadingPosition={submitLoading ? 'start' : undefined}
                                  startIcon={submitLoading && <SaveIcon />}
                                  variant="contained"
                                  type="submit">
                                  Save Changes
                                </LoadingButton>
                              )}
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Divider />
                          </Grid>
                          {/* temporarily checking with role test instead of commenting whole code block */}
                          {authCtx.user.role === 'test' && (
                            <>
                              <Grid item xs={12} md={12}>
                                <Typography variant="h5">Subscription Plans</Typography>
                              </Grid>
                              {products
                                ?.filter((item) => item.active)
                                .map((product, index) => (
                                  <>
                                    <Grid item xs={12} md={2}>
                                      <Box
                                        className="product-box"
                                        // style={{ width: '250px' }}
                                        key={product.id}>
                                        <Checkbox
                                          disabled={scheduledPrices?.includes(product.price_id)}
                                          id={product.id}
                                          name={product.name}
                                          checked={
                                            checked.find(
                                              (item) => item.product.price_id === product.price_id
                                            ) || scheduledPrices?.includes(product.price_id)
                                          }
                                          onChange={(e) =>
                                            handleCheckChange(
                                              e,
                                              product.id,
                                              product.price_id || product.default_price,
                                              getProductQuantity(product.name)
                                            )
                                          }
                                          inputProps={{
                                            'aria-label': 'controlled'
                                          }}
                                        />
                                        <Typography variant="h6">{product.name}</Typography>
                                        <Typography variant="subtitle1">
                                          Price: ${' '}
                                          {product.unit_amount
                                            ? (product.unit_amount / 100) *
                                              (product.name == 'Mobile Live Stream Room License'
                                                ? authCtx.user.max_stream_live_license_room
                                                : product.name ===
                                                  'Sentry Perimeter Monitoring License'
                                                ? authCtx.user.max_stream_live_license
                                                : 1)
                                            : '--'}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                          Qty: {getProductQuantity(product.name)}
                                        </Typography>
                                        {/* <Button onClick={() => handleDecrement(product.id)}>-</Button>
                                      <Input
                                        type="number"
                                        value={productQuantities[product.id] || 0}
                                      />
                                      <Button onClick={() => handleIncrement(product.id)}>+</Button> */}
                                      </Box>
                                    </Grid>
                                  </>
                                ))}
                              <Grid item xs={12} md={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Typography variant="h5">Set Free Trial Period</Typography>
                              </Grid>
                              <Grid item md={6}>
                                <Stack direction={'row'} gap={2} alignItems={'center'}>
                                  <Box>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <InputLabel id="from">Start Date</InputLabel>
                                      <DesktopDatePicker
                                        disablePast
                                        open={isDatePickerOpen}
                                        // maxDate={moment().add(trialDays, 'days')}
                                        labelId="start_date"
                                        autoOk={true}
                                        value={startDate}
                                        inputFormat="MM/DD/YY"
                                        onClose={() => setIsDatePickerOpen(false)}
                                        renderInput={(params) => (
                                          <TextField
                                            onClick={() => setIsDatePickerOpen(true)}
                                            {...params}
                                          />
                                        )}
                                        components={{
                                          OpenPickerIcon: !isDatePickerOpen
                                            ? ArrowDropDownIcon
                                            : ArrowDropUpIcon
                                        }}
                                        onChange={(value) => {
                                          setStartDate(value);
                                        }}
                                      />
                                    </LocalizationProvider>
                                  </Box>
                                  <Box>
                                    <InputLabel id="from">No. of Days</InputLabel>
                                    <TextField
                                      name={'no_of_days'}
                                      type="number"
                                      value={trialDays}
                                      InputProps={{ inputProps: { min: 0, max: 45, step: 1 } }}
                                      onChange={(event) => {
                                        setTrialDays(event.target.value);
                                      }}
                                      fullWidth
                                    />
                                  </Box>
                                </Stack>
                              </Grid>
                            </>
                          )}
                          {authCtx.user.role === 'test' && (
                            <Grid item xs={12} md={12}>
                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={3}>
                                {authCtx.user.role === 'Super Admin' && (
                                  <Button
                                    sx={{
                                      '&:disabled': {
                                        backgroundColor: '#6e66c724 !important'
                                      }
                                    }}
                                    variant="contained"
                                    disabled={checked.length == 0}
                                    onClick={handleCheckout}>
                                    Start Service
                                  </Button>
                                )}
                              </Stack>
                            </Grid>
                          )}
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box className="listing-wrapper">
            <Card className="filter">
              <CardContent>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={9} sm={12}>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item md={4} sm={12}>
                            <InputLabel id="search">Search</InputLabel>
                            <TextField
                              labelId="search"
                              placeholder="Location"
                              onChange={debouncedResults}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sm={12}
                      sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Box>
                        <Button
                          className="add-button"
                          variant="contained"
                          startIcon={<Plus />}
                          onClick={() => setIsUserFormDialogOpen(true)}>
                          {' '}
                          Add Location
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Box mt={2} position="relative">
                  <LinerLoader loading={isLoading} />
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ minWidth: '100px' }} align="left">
                            Location
                          </TableCell>
                          <TableCell align="left">Status</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {locationsList?.length > 0
                          ? locationsList?.map((row, index) => (
                              <TableRow key={index} hover>
                                <TableCell align="left">
                                  <Stack direction="row">
                                    <Chip
                                      key={index}
                                      label={row.loc_name}
                                      color="primary"
                                      className="chip-color"
                                    />
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  {row.status ? 'Active' : 'Inactive'}
                                </TableCell>
                                <TableCell align="right">
                                  <SettingsActions
                                    location={row}
                                    setLocation={setLocation}
                                    setIsUserFormDialogOpen={setIsUserFormDialogOpen}
                                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                    {!isLoading && locationsList?.length == 0 ? <NoDataDiv /> : null}
                    {locationsList?.length > 0 ? (
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 25, 50]}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        component="div"
                        count={totalLocations}
                        rowsPerPage={usersPayload?.pageSize}
                        page={usersPayload?.pageNumber}
                        sx={{ flex: '1 1 auto' }}
                      />
                    ) : null}
                  </TableContainer>
                </Box>
              </CardContent>
            </Card>
            {isUserFormDialogOpen && (
              <SettingsForm
                open={isUserFormDialogOpen}
                location={location}
                locationsList={locationsList}
                customer={customerDetails}
                activeLocations={activeLocations}
                setOpen={setIsUserFormDialogOpen}
                getLocationsList={getLocationsList}
                setLocation={setLocation}
              />
            )}
            {/* <DeleteDialog
              open={isDeleteDialogOpen}
              title="Delete User"
              contentText={'Are you sure you want to delete this location?'}
              loading={deleteLoading}
              handleDialogClose={() => {
                setLocation();
                setIsDeleteDialogOpen(false);
              }}
              handleDelete={handleLocationDelete}
            /> */}

            <NewDeleteDialog
              open={isDeleteDialogOpen}
              title="Delete location"
              contentText="Are you sure you want to delete this location?"
              loading={deleteLoading}
              handleDialogClose={() => {
                setLocation();
                setIsDeleteDialogOpen(false);
              }}
              handleDelete={handleLocationDelete}
            />
          </Box>
        </TabPanel>
        <TabPanel value="3">
          <DefaultScheduler
            // settings={true}
            custId={authCtx.user.cust_id || localStorage.getItem('cust_id')}
            timer={timer}
            selectedDays={selectedDays}
            getDefaultScheduleSettings={getDefaultScheduleSettings}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Settings;

export const timezones = [
  {
    timezone: 'Africa/Abidjan',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Accra',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Addis_Ababa',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Algiers',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Asmara',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Asmera',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Bamako',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Bangui',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Banjul',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Bissau',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Blantyre',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Brazzaville',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Bujumbura',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Cairo',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Casablanca',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Ceuta',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Conakry',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Dakar',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Dar_es_Salaam',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Djibouti',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Douala',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/El_Aaiun',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Freetown',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Gaborone',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Harare',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Johannesburg',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Juba',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Kampala',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Khartoum',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Kigali',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Kinshasa',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Lagos',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Libreville',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Lome',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Luanda',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Lubumbashi',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Lusaka',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Malabo',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Maputo',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Maseru',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Mbabane',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Mogadishu',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Monrovia',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Nairobi',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Africa/Ndjamena',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Niamey',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Nouakchott',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Ouagadougou',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Porto-Novo',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Sao_Tome',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Timbuktu',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Africa/Tripoli',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Africa/Tunis',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Africa/Windhoek',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'America/Adak',
    offset: -36000,
    offset_dst: -32400
  },
  {
    timezone: 'America/Anchorage',
    offset: -32400,
    offset_dst: -28800
  },
  {
    timezone: 'America/Anguilla',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Antigua',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Araguaina',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Buenos_Aires',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Catamarca',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/ComodRivadavia',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Cordoba',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Jujuy',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/La_Rioja',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Mendoza',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Rio_Gallegos',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Salta',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/San_Juan',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/San_Luis',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Tucuman',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Argentina/Ushuaia',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Aruba',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Asuncion',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Atikokan',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Atka',
    offset: -36000,
    offset_dst: -32400
  },
  {
    timezone: 'America/Bahia',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Bahia_Banderas',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Barbados',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Belem',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Belize',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'America/Blanc-Sablon',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Boa_Vista',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Bogota',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Boise',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Buenos_Aires',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Cambridge_Bay',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Campo_Grande',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'America/Cancun',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Caracas',
    offset: -16200,
    offset_dst: -16200
  },
  {
    timezone: 'America/Catamarca',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Cayenne',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Cayman',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Chicago',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Chihuahua',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Coral_Harbour',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Cordoba',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Costa_Rica',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'America/Creston',
    offset: -25200,
    offset_dst: -25200
  },
  {
    timezone: 'America/Cuiaba',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'America/Curacao',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Danmarkshavn',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'America/Dawson',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'America/Dawson_Creek',
    offset: -25200,
    offset_dst: -25200
  },
  {
    timezone: 'America/Denver',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Detroit',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Dominica',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Edmonton',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Eirunepe',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/El_Salvador',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'America/Ensenada',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'America/Fort_Nelson',
    offset: -25200,
    offset_dst: -25200
  },
  {
    timezone: 'America/Fort_Wayne',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Fortaleza',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Glace_Bay',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'America/Godthab',
    offset: -10800,
    offset_dst: -7200
  },
  {
    timezone: 'America/Goose_Bay',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'America/Grand_Turk',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Grenada',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Guadeloupe',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Guatemala',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'America/Guayaquil',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Guyana',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Halifax',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'America/Havana',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Hermosillo',
    offset: -25200,
    offset_dst: -25200
  },
  {
    timezone: 'America/Indiana/Indianapolis',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Indiana/Knox',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Indiana/Marengo',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Indiana/Petersburg',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Indiana/Tell_City',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Indiana/Vevay',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Indiana/Vincennes',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Indiana/Winamac',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Indianapolis',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Inuvik',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Iqaluit',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Jamaica',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Jujuy',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Juneau',
    offset: -32400,
    offset_dst: -28800
  },
  {
    timezone: 'America/Kentucky/Louisville',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Kentucky/Monticello',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Knox_IN',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Kralendijk',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/La_Paz',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Lima',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Los_Angeles',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'America/Louisville',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Lower_Princes',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Maceio',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Managua',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'America/Manaus',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Marigot',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Martinique',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Matamoros',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Mazatlan',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Mendoza',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Menominee',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Merida',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Metlakatla',
    offset: -28800,
    offset_dst: -28800
  },
  {
    timezone: 'America/Mexico_City',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Miquelon',
    offset: -10800,
    offset_dst: -7200
  },
  {
    timezone: 'America/Moncton',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'America/Monterrey',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Montevideo',
    offset: -10800,
    offset_dst: -7200
  },
  {
    timezone: 'America/Montreal',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Montserrat',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Nassau',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/New_York',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Nipigon',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Nome',
    offset: -32400,
    offset_dst: -28800
  },
  {
    timezone: 'America/Noronha',
    offset: -7200,
    offset_dst: -7200
  },
  {
    timezone: 'America/North_Dakota/Beulah',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/North_Dakota/Center',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/North_Dakota/New_Salem',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Ojinaga',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Panama',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Pangnirtung',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Paramaribo',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Phoenix',
    offset: -25200,
    offset_dst: -25200
  },
  {
    timezone: 'America/Port-au-Prince',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Port_of_Spain',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Porto_Acre',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Porto_Velho',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Puerto_Rico',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Rainy_River',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Rankin_Inlet',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Recife',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Regina',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'America/Resolute',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Rio_Branco',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'America/Rosario',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Santa_Isabel',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'America/Santarem',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Santiago',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'America/Santo_Domingo',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Sao_Paulo',
    offset: -10800,
    offset_dst: -7200
  },
  {
    timezone: 'America/Scoresbysund',
    offset: -3600,
    offset_dst: 0
  },
  {
    timezone: 'America/Shiprock',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'America/Sitka',
    offset: -32400,
    offset_dst: -28800
  },
  {
    timezone: 'America/St_Barthelemy',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/St_Johns',
    offset: -12600,
    offset_dst: -9000
  },
  {
    timezone: 'America/St_Kitts',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/St_Lucia',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/St_Thomas',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/St_Vincent',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Swift_Current',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'America/Tegucigalpa',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'America/Thule',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'America/Thunder_Bay',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Tijuana',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'America/Toronto',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'America/Tortola',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Vancouver',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'America/Virgin',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'America/Whitehorse',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'America/Winnipeg',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'America/Yakutat',
    offset: -32400,
    offset_dst: -28800
  },
  {
    timezone: 'America/Yellowknife',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'Antarctica/Casey',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Antarctica/Davis',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Antarctica/DumontDUrville',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Antarctica/Macquarie',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Antarctica/Mawson',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Antarctica/McMurdo',
    offset: 43200,
    offset_dst: 46800
  },
  {
    timezone: 'Antarctica/Palmer',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'Antarctica/Rothera',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'Antarctica/South_Pole',
    offset: 43200,
    offset_dst: 46800
  },
  {
    timezone: 'Antarctica/Syowa',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Antarctica/Troll',
    offset: 0,
    offset_dst: 7200
  },
  {
    timezone: 'Antarctica/Vostok',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Arctic/Longyearbyen',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Asia/Aden',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Almaty',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Amman',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Anadyr',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Asia/Aqtau',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Aqtobe',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Ashgabat',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Ashkhabad',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Baghdad',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Bahrain',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Baku',
    offset: 14400,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Bangkok',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Beirut',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Bishkek',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Brunei',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Calcutta',
    offset: 19800,
    offset_dst: 19800
  },
  {
    timezone: 'Asia/Chita',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Choibalsan',
    offset: 28800,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Chongqing',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Chungking',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Colombo',
    offset: 19800,
    offset_dst: 19800
  },
  {
    timezone: 'Asia/Dacca',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Damascus',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Dhaka',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Dili',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Dubai',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Asia/Dushanbe',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Gaza',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Harbin',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Hebron',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Ho_Chi_Minh',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Hong_Kong',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Hovd',
    offset: 25200,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Irkutsk',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Istanbul',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Jakarta',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Jayapura',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Jerusalem',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Kabul',
    offset: 16200,
    offset_dst: 16200
  },
  {
    timezone: 'Asia/Kamchatka',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Asia/Karachi',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Kashgar',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Kathmandu',
    offset: 20700,
    offset_dst: 20700
  },
  {
    timezone: 'Asia/Katmandu',
    offset: 20700,
    offset_dst: 20700
  },
  {
    timezone: 'Asia/Khandyga',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Kolkata',
    offset: 19800,
    offset_dst: 19800
  },
  {
    timezone: 'Asia/Krasnoyarsk',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Kuala_Lumpur',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Kuching',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Kuwait',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Macao',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Macau',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Magadan',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Asia/Makassar',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Manila',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Muscat',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Asia/Nicosia',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Novokuznetsk',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Novosibirsk',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Omsk',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Oral',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Phnom_Penh',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Pontianak',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Pyongyang',
    offset: 30600,
    offset_dst: 30600
  },
  {
    timezone: 'Asia/Qatar',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Qyzylorda',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Rangoon',
    offset: 23400,
    offset_dst: 23400
  },
  {
    timezone: 'Asia/Riyadh',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Riyadh87',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Riyadh88',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Riyadh89',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Saigon',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Sakhalin',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Asia/Samarkand',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Seoul',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Shanghai',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Singapore',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Srednekolymsk',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Asia/Taipei',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Tashkent',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Tbilisi',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Asia/Tehran',
    offset: 12600,
    offset_dst: 16200
  },
  {
    timezone: 'Asia/Tel_Aviv',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Asia/Thimbu',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Thimphu',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Tokyo',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Ujung_Pandang',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Asia/Ulaanbaatar',
    offset: 28800,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Ulan_Bator',
    offset: 28800,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Urumqi',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Asia/Vientiane',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Asia/Vladivostok',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Asia/Yakutsk',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Asia/Yekaterinburg',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Asia/Yerevan',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Atlantic/Azores',
    offset: -3600,
    offset_dst: 0
  },
  {
    timezone: 'Atlantic/Bermuda',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'Atlantic/Canary',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Atlantic/Cape_Verde',
    offset: -3600,
    offset_dst: -3600
  },
  {
    timezone: 'Atlantic/Faeroe',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Atlantic/Faroe',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Atlantic/Jan_Mayen',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Atlantic/Madeira',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Atlantic/Reykjavik',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Atlantic/South_Georgia',
    offset: -7200,
    offset_dst: -7200
  },
  {
    timezone: 'Atlantic/St_Helena',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Atlantic/Stanley',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'Australia/ACT',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/Adelaide',
    offset: 34200,
    offset_dst: 37800
  },
  {
    timezone: 'Australia/Brisbane',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Australia/Broken_Hill',
    offset: 34200,
    offset_dst: 37800
  },
  {
    timezone: 'Australia/Canberra',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/Currie',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/Darwin',
    offset: 34200,
    offset_dst: 34200
  },
  {
    timezone: 'Australia/Eucla',
    offset: 31500,
    offset_dst: 31500
  },
  {
    timezone: 'Australia/Hobart',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/LHI',
    offset: 37800,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/Lindeman',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Australia/Lord_Howe',
    offset: 37800,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/Melbourne',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/NSW',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/North',
    offset: 34200,
    offset_dst: 34200
  },
  {
    timezone: 'Australia/Perth',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Australia/Queensland',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Australia/South',
    offset: 34200,
    offset_dst: 37800
  },
  {
    timezone: 'Australia/Sydney',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/Tasmania',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/Victoria',
    offset: 36000,
    offset_dst: 39600
  },
  {
    timezone: 'Australia/West',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Australia/Yancowinna',
    offset: 34200,
    offset_dst: 37800
  },
  {
    timezone: 'Brazil/Acre',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'Brazil/DeNoronha',
    offset: -7200,
    offset_dst: -7200
  },
  {
    timezone: 'Brazil/East',
    offset: -10800,
    offset_dst: -7200
  },
  {
    timezone: 'Brazil/West',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'CET',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Canada/Atlantic',
    offset: -14400,
    offset_dst: -10800
  },
  {
    timezone: 'Canada/Central',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'Canada/East-Saskatchewan',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'Canada/Eastern',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'Canada/Mountain',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'Canada/Newfoundland',
    offset: -12600,
    offset_dst: -9000
  },
  {
    timezone: 'Canada/Pacific',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'Canada/Saskatchewan',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'Canada/Yukon',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'Chile/Continental',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'Chile/EasterIsland',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'Cuba',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'EST',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'Egypt',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Eire',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Etc/GMT',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Etc/GMT+0',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Etc/GMT+1',
    offset: -3600,
    offset_dst: -3600
  },
  {
    timezone: 'Etc/GMT+10',
    offset: -36000,
    offset_dst: -36000
  },
  {
    timezone: 'Etc/GMT+11',
    offset: -39600,
    offset_dst: -39600
  },
  {
    timezone: 'Etc/GMT+12',
    offset: -43200,
    offset_dst: -43200
  },
  {
    timezone: 'Etc/GMT+2',
    offset: -7200,
    offset_dst: -7200
  },
  {
    timezone: 'Etc/GMT+3',
    offset: -10800,
    offset_dst: -10800
  },
  {
    timezone: 'Etc/GMT+4',
    offset: -14400,
    offset_dst: -14400
  },
  {
    timezone: 'Etc/GMT+5',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'Etc/GMT+6',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'Etc/GMT+7',
    offset: -25200,
    offset_dst: -25200
  },
  {
    timezone: 'Etc/GMT+8',
    offset: -28800,
    offset_dst: -28800
  },
  {
    timezone: 'Etc/GMT+9',
    offset: -32400,
    offset_dst: -32400
  },
  {
    timezone: 'Etc/GMT-0',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Etc/GMT-1',
    offset: 3600,
    offset_dst: 3600
  },
  {
    timezone: 'Etc/GMT-10',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Etc/GMT-11',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Etc/GMT-12',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Etc/GMT-13',
    offset: 46800,
    offset_dst: 46800
  },
  {
    timezone: 'Etc/GMT-14',
    offset: 50400,
    offset_dst: 50400
  },
  {
    timezone: 'Etc/GMT-2',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Etc/GMT-3',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Etc/GMT-4',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Etc/GMT-5',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Etc/GMT-6',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Etc/GMT-7',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Etc/GMT-8',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Etc/GMT-9',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Etc/GMT0',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Etc/Greenwich',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Etc/UCT',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Etc/UTC',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Etc/Universal',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Etc/Zulu',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Europe/Amsterdam',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Andorra',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Athens',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Belfast',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Europe/Belgrade',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Berlin',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Bratislava',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Brussels',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Bucharest',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Budapest',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Busingen',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Chisinau',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Copenhagen',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Dublin',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Europe/Gibraltar',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Guernsey',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Europe/Helsinki',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Isle_of_Man',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Europe/Istanbul',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Jersey',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Europe/Kaliningrad',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Kiev',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Lisbon',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Europe/Ljubljana',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/London',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Europe/Luxembourg',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Madrid',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Malta',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Mariehamn',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Minsk',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Monaco',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Moscow',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Nicosia',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Oslo',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Paris',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Podgorica',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Prague',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Riga',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Rome',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Samara',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Europe/San_Marino',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Sarajevo',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Simferopol',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Skopje',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Sofia',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Stockholm',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Tallinn',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Tirane',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Tiraspol',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Uzhgorod',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Vaduz',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Vatican',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Vienna',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Vilnius',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Volgograd',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Warsaw',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Zagreb',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Europe/Zaporozhye',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Europe/Zurich',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'GMT',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'HST',
    offset: -36000,
    offset_dst: -36000
  },
  {
    timezone: 'Hongkong',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Iceland',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Indian/Antananarivo',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Indian/Chagos',
    offset: 21600,
    offset_dst: 21600
  },
  {
    timezone: 'Indian/Christmas',
    offset: 25200,
    offset_dst: 25200
  },
  {
    timezone: 'Indian/Cocos',
    offset: 23400,
    offset_dst: 23400
  },
  {
    timezone: 'Indian/Comoro',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Indian/Kerguelen',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Indian/Mahe',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Indian/Maldives',
    offset: 18000,
    offset_dst: 18000
  },
  {
    timezone: 'Indian/Mauritius',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Indian/Mayotte',
    offset: 10800,
    offset_dst: 10800
  },
  {
    timezone: 'Indian/Reunion',
    offset: 14400,
    offset_dst: 14400
  },
  {
    timezone: 'Iran',
    offset: 12600,
    offset_dst: 16200
  },
  {
    timezone: 'Israel',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'Jamaica',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'Japan',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Kwajalein',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Libya',
    offset: 7200,
    offset_dst: 7200
  },
  {
    timezone: 'Mexico/BajaNorte',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'Mexico/BajaSur',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'Mexico/General',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'Mideast/Riyadh87',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Mideast/Riyadh88',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Mideast/Riyadh89',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'Pacific/Apia',
    offset: 46800,
    offset_dst: 50400
  },
  {
    timezone: 'Pacific/Auckland',
    offset: 43200,
    offset_dst: 46800
  },
  {
    timezone: 'Pacific/Bougainville',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Pacific/Chatham',
    offset: 45900,
    offset_dst: 49500
  },
  {
    timezone: 'Pacific/Chuuk',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Pacific/Easter',
    offset: -18000,
    offset_dst: -18000
  },
  {
    timezone: 'Pacific/Efate',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Pacific/Enderbury',
    offset: 46800,
    offset_dst: 46800
  },
  {
    timezone: 'Pacific/Fakaofo',
    offset: 46800,
    offset_dst: 46800
  },
  {
    timezone: 'Pacific/Fiji',
    offset: 43200,
    offset_dst: 46800
  },
  {
    timezone: 'Pacific/Funafuti',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Pacific/Galapagos',
    offset: -21600,
    offset_dst: -21600
  },
  {
    timezone: 'Pacific/Gambier',
    offset: -32400,
    offset_dst: -32400
  },
  {
    timezone: 'Pacific/Guadalcanal',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Pacific/Guam',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Pacific/Honolulu',
    offset: -36000,
    offset_dst: -36000
  },
  {
    timezone: 'Pacific/Johnston',
    offset: -36000,
    offset_dst: -36000
  },
  {
    timezone: 'Pacific/Kiritimati',
    offset: 50400,
    offset_dst: 50400
  },
  {
    timezone: 'Pacific/Kosrae',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Pacific/Kwajalein',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Pacific/Majuro',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Pacific/Marquesas',
    offset: -34200,
    offset_dst: -34200
  },
  {
    timezone: 'Pacific/Midway',
    offset: -39600,
    offset_dst: -39600
  },
  {
    timezone: 'Pacific/Nauru',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Pacific/Niue',
    offset: -39600,
    offset_dst: -39600
  },
  {
    timezone: 'Pacific/Norfolk',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Pacific/Noumea',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Pacific/Pago_Pago',
    offset: -39600,
    offset_dst: -39600
  },
  {
    timezone: 'Pacific/Palau',
    offset: 32400,
    offset_dst: 32400
  },
  {
    timezone: 'Pacific/Pitcairn',
    offset: -28800,
    offset_dst: -28800
  },
  {
    timezone: 'Pacific/Ponape',
    offset: 39600,
    offset_dst: 39600
  },
  {
    timezone: 'Pacific/Port_Moresby',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Pacific/Rarotonga',
    offset: -36000,
    offset_dst: -36000
  },
  {
    timezone: 'Pacific/Saipan',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Pacific/Samoa',
    offset: -39600,
    offset_dst: -39600
  },
  {
    timezone: 'Pacific/Tahiti',
    offset: -36000,
    offset_dst: -36000
  },
  {
    timezone: 'Pacific/Tarawa',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Pacific/Tongatapu',
    offset: 46800,
    offset_dst: 46800
  },
  {
    timezone: 'Pacific/Truk',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Pacific/Wake',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Pacific/Wallis',
    offset: 43200,
    offset_dst: 43200
  },
  {
    timezone: 'Pacific/Yap',
    offset: 36000,
    offset_dst: 36000
  },
  {
    timezone: 'Poland',
    offset: 3600,
    offset_dst: 7200
  },
  {
    timezone: 'Portugal',
    offset: 0,
    offset_dst: 3600
  },
  {
    timezone: 'Singapore',
    offset: 28800,
    offset_dst: 28800
  },
  {
    timezone: 'Turkey',
    offset: 7200,
    offset_dst: 10800
  },
  {
    timezone: 'UCT',
    offset: 0,
    offset_dst: 0
  },
  {
    timezone: 'US/Alaska',
    offset: -32400,
    offset_dst: -28800
  },
  {
    timezone: 'US/Aleutian',
    offset: -36000,
    offset_dst: -32400
  },
  {
    timezone: 'US/Arizona',
    offset: -25200,
    offset_dst: -25200
  },
  {
    timezone: 'US/Central',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'US/East-Indiana',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'US/Eastern',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'US/Hawaii',
    offset: -36000,
    offset_dst: -36000
  },
  {
    timezone: 'US/Indiana-Starke',
    offset: -21600,
    offset_dst: -18000
  },
  {
    timezone: 'US/Michigan',
    offset: -18000,
    offset_dst: -14400
  },
  {
    timezone: 'US/Mountain',
    offset: -25200,
    offset_dst: -21600
  },
  {
    timezone: 'US/Pacific',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'US/Pacific-New',
    offset: -28800,
    offset_dst: -25200
  },
  {
    timezone: 'US/Samoa',
    offset: -39600,
    offset_dst: -39600
  },
  {
    timezone: 'UTC',
    offset: 0,
    offset_dst: 0
  }
];

export const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971'
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268'
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true
  },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387'
  },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61'
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243'
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236'
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242'
  },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true
  },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809'
  },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500'
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691'
  },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true
  },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500'
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672'
  },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246'
  },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98'
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true
  },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869'
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850'
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856'
  },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373'
  },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590'
  },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389'
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670'
  },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508'
  },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970'
  },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47'
  },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239'
  },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721'
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963'
  },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649'
  },
  { code: 'TD', label: 'Chad', phone: '235' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262'
  },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868'
  },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    code: 'TW',
    label: 'Taiwan',
    phone: '886'
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255'
  },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379'
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784'
  },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284'
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340'
  },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' }
];
